




















































































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import SearchBar from "@/components/search-bar.vue";
import i18n from "@/i18n";
export default Vue.extend({
  props: {
    searchText: {
      type: String,
    },
  },
  components: {
    "search-bar": SearchBar,
  },

  computed: {
    ...mapGetters("azVenuesModule", {
      allVenues: "GET_ALL_VENUES",
    }),
    ...mapGetters("azBusinessTypeModule", {
      businessTypes: "GET_BUSINESS_TYPES",
    }),
    loc_notifications() {
      let temp_notification = this.notifications.map(item => {
        return {
          ...item,
          languages: [
            {
              lang: "English",
              translated: true,
              translation_changed: false,
            },
            {
              lang: "Slovakia",
              translated: true,
              translation_changed: false,
            },
          ],
        };
      });
      if (this.searchText != "") {
        return temp_notification.filter(item =>
          item.clientZoneNotificationType
            .toString()
            .includes(this.searchText.toLowerCase())
        );
      } else {
        return temp_notification;
      }
    },

    fields() {
      return [
        {
          key: "name",
          label: this.$i18n.t("Business type"),
          tdClass: "align-middle"
        },
        {
          key: "playlist",
          label: this.$i18n.t("Playlist"),
          tdClass: "align-middle"
        },
        {
          key: "default",
          label: this.$i18n.t("Default"),
          tdClass: "align-middle"
        },
        {
          key: "actions",
          label: this.$i18n.t("Action"),
          thClass: "text-right",
          tdClass: "text-right",
        },
      ];
    },
    itemToDeleteName() {
      if (!this.itemToDelete) {
        return '';
      }

      if (this.$i18n.locale === 'sk') {
        return this.itemToDelete.name_sk;
      } else {
        return this.itemToDelete.name_en;
      }

    }
  },
  data() {
    return {
      filter: null,
      currentPage: 1,
      perPage: 10,
      addModal: false,
      editModal: false,
      deleteModal: false,
      editLoading: false,
      itemToDelete: null,
      deleteLoading: false,
      flag_en: require("@/assets/images/flags/en.png"),
      flag_sk: require("@/assets/images/flags/sk.png"),
      flag_cs: require("@/assets/images/flags/cs.png"),
      itemToEdit: {
        name_en: null,
        name_sk: null,
        venue_type_id: null,
        default_business_type: false,
      },
    };
  },
  methods: {
    ...mapActions("azBusinessTypeModule", {
      updateBusinessType: "UPDATE_BUSINESS_TYPE",
    }),
    ...mapActions("azBusinessTypeModule", {
      removeBusinessType: "DELETE_BUSINESS_TYPE",
    }),
    edit(item) {
      this.itemToEdit = Object.assign({}, item);
      this.editModal = true;
    },
    remove(item) {
      this.itemToDelete = Object.assign({}, item);
      this.deleteModal = true;
    },
    getName(item): string {
      return this.$i18n.locale === 'en' ? item.name_en : item.name_sk;
    },
    getPlaylist(id) {
      if (this.allVenues.length) {
        const item = this.allVenues.find(v => v.venue_type_id === id);
        if (item) {
          return item.name;
        }
      }
      return "N/A";
    },
    async editBusinessType() {
      let name = "";

      if (this.itemToEdit.name_en) {
        name = "en|" + this.itemToEdit.name_en;
      }

      if (this.itemToEdit.name_sk) {
        name += "\nsk|" + this.itemToEdit.name_sk;
      }

      const data = {
        name: name,
        id: this.itemToEdit.id,
        venue_type_id: this.itemToEdit.venue_type_id,
        default_business_type: this.itemToEdit.default_business_type,
      };

      this.editLoading = true;
      await this.updateBusinessType(data);
      this.editLoading = false;
      this.editModal = false;
    },
    async deleteBusinessType() {
      const data = Object.assign({}, this.itemToDelete);
      this.deleteLoading = true;
      await this.removeBusinessType(data);
      this.deleteLoading = false;
      this.deleteModal = false;
    },
  },
});
