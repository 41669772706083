

















































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import BusinessTypeTable from "@/components/businessType/BusinessTypeTable.vue";
import { mapGetters, mapActions } from "vuex";
export default Vue.extend({
  components: {
    Layout,
    BusinessTypeTable,
  },
  async created() {
    await this.fetchAllVenues();
    await this.fetchBusinessTypes();
  },
  computed: {
    ...mapGetters("azVenuesModule", {
      allVenues: "GET_ALL_VENUES",
    }),
    ...mapGetters("azBusinessTypeModule", {
      businessTypes: "GET_BUSINESS_TYPES",
    }),
  },
  methods: {
    ...mapActions("azVenuesModule", {
      fetchAllVenues: "FETCH_ALL_VENUES",
    }),
    ...mapActions("azBusinessTypeModule", {
      fetchBusinessTypes: "FETCH_BUSINESS_TYPES",
      addNewBusinessType: "ADD_BUSINESS_TYPE",
      setDefaultBusinessType: "SET_DEFAULT_BUSINESS_TYPE",
    }),
    getSearchValue(val) {
      this.searchText = val;
    },
    async addBusinessType() {
      var name = "";

      if (this.newBusinessType.name_en) {
        name = "en|" + this.newBusinessType.name_en;
      }

      if (this.newBusinessType.name_sk) {
        name += "\nsk|" + this.newBusinessType.name_sk;
      }

      var data = {
        name: name,
        venue_type_id: this.newBusinessType.venue_type_id,
      };

      //const data = Object.assign({}, this.newBusinessType);
      this.addLoading = true;
      const newBusinessTypeId = await this.addNewBusinessType(data, this.$i18n.locale);
      this.businessTypes;
      this.addLoading = false;
      this.addModal = false;

      if (newBusinessTypeId && this.isDefault === true) {
        this.setDefaultBusinessType({id: newBusinessTypeId, isDefault: this.isDefault});
      }
    },
    openModal() {
      this.addModal = true;
      this.$set(this.newBusinessType, "name_en", null);
      this.$set(this.newBusinessType, "name_sk", null);
      this.$set(this.newBusinessType, "venue_type_id", null);
    },
  },
  data() {
    let title = this.$t("Business type");
    return {
      addModal: false,
      title: title,
      searchText: "",
      addLoading: false,
      flag_en: require("@/assets/images/flags/en.png"),
      flag_sk: require("@/assets/images/flags/sk.png"),
      flag_cs: require("@/assets/images/flags/cs.png"),
      newBusinessType: {
        name_en: null,
        name_sk: null,
        venue_type_id: null,
      },
      isDefault: false,
    };
  },
  watch: {
    "$i18n.locale": async function (newVal, oldVal) {
      await this.fetchBusinessTypes();
    },
  },
});
