import { render, staticRenderFns } from "./BusinessType.vue?vue&type=template&id=5d8f841a&scoped=true&"
import script from "./BusinessType.vue?vue&type=script&lang=ts&"
export * from "./BusinessType.vue?vue&type=script&lang=ts&"
import style0 from "./BusinessType.vue?vue&type=style&index=0&id=5d8f841a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d8f841a",
  null
  
)

export default component.exports